:root {
  --background-purple: #ece9f5;
  --white: #ffffff;
  --black: #000000;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.songkick-widget {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  color: var(--black);
}

.songkick-widget:hover {
  color: var(--black);
  text-decoration: none;
  opacity: 0.5;
  transition: 0.3s;
}

#navigation-bar-items {
  padding: 7px 0px;
}

#navigation-bar-items p {
  margin: 0px;
}

#album-art {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.social-link {
  text-decoration: none;
  color: black;
  margin: 20px;
}

.page-section-link {
  text-decoration: none;
  color: black;
  margin: 10px;
}

.page-section-link:hover {
  text-decoration: none;
  color: black;
  margin: 10px;
  opacity: 0.5;
  transition: 0.3s;
}

.social-link:hover {
  text-decoration: none;
  color: black;
  transition: 0.3s;
}

#instagram-link:hover {
  color: #ef329a;
  transition: 0.3s;
}

#facebook-link:hover {
  color: #1675f2;
  transition: 0.3s;
}

#twitter-link:hover {
  color: #1ea2fa;
  transition: 0.3s;
}

#tiktok-link:hover {
  color: #52ead8;
  transition: 0.3s;
}

#youtube-link:hover {
  color: #d60202;
  transition: 0.3s;
}

p {
  font-family: "Barlow Semi Condensed";
  font-size: 20px;
}

h2 {
  font-family: "Barlow Semi Condensed";
  font-size: 50px;
}

#header-left {
  display: flex;
}

#header-left > p {
  margin-left: 20px;
}

#header-left > p:hover {
  opacity: 0.5;
  transition: 0.3s;
}

#header-center {
  display: flex;
  padding-left: 10vw;
}

.row {
  margin: 0 !important;
}

input[type="text"] {
  border: 3px solid #b7d498;
  padding: 2px;
}

input[type="text"]:focus {
  outline: none;
}

input[type="submit"] {
  border: 3px solid #b7d498;
  padding: 2px;
  background: none;
}

input[type="submit"]:hover {
  cursor: pointer;
}

#hp {
  display: none;
}

.events-listing {
  margin: auto;
  width: 70%;
}

.events-listing > ol {
  margin-top: 25px;
  list-style-type: none;
  padding: 0px;
}

.events-listing > ol > li {
  margin: 10px 0px;
}

.events-listing > ol > li > a,
.events-listing > ol > li > div {
  display: flex;
  text-decoration: none;
  color: black;
  flex-wrap: wrap;
}

.events-listing > ol > li > a:hover {
  color: black;
  opacity: 0.6;
  transition: 0.2s;
}

.events-card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.events-card .price-detail {
  color: grey;
  font-size: 16px;
}

.events-card > a > div > i,
.events-card > div > div > i {
  margin: 20px;
  padding-top: 12px;
}

.event-date {
  padding: 10px 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.event-date > h3 {
  margin-bottom: 0px;
  text-align: center;
}

.event-date > h4 {
  margin-bottom: 0px;
  text-align: center;
}

.event-place {
  padding: 10px 20px;
  margin-right: auto;
  margin-left: auto;
}

.event-place > p {
  margin-bottom: 0px;
  text-align: center;
}

#carouselExampleControls {
  width: 65vw;
  margin: auto;
}

.carousel-inner {
  border-radius: 10px;
}

.btn-dark {
  margin-top: 15px;
  font-family: "Barlow Semi Condensed";
}

.press-cards {
  margin-bottom: 20px;
}

.press-card {
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 300px;
  text-align: center;
  padding: 25px 35px;
  background-color: #272c31;
  border: 1px solid #a7a3b0;
  color: #ffffff;
}

.press-card > div {
  margin-bottom: 30px;
}

.press-card > div > p {
  font-size: 16px;
  color: #cac7d2;
  margin: 0px;
}

.press-card i {
  font-size: 1.3em;
  top: 2px;
  position: relative;
  margin-right: 5px;
}

#contact {
  margin-bottom: 50px;
}

#contact h2 {
  margin-top: 50px;
}

#epk-bio .epk-band-img {
  width: 100%;
  margin: 60px 0px;
}

#epk-bio p {
  text-align: center;
  font-size: 17px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown span {
  position: relative;
  bottom: 1px;
  margin-left: 3px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content hr {
  margin: 0px 8px 0px 8px;
}

.dropdown-content p {
  margin: 12px !important;
}

.downloadable-files {
  margin-top: 20px;
  width: fit-content;
}

.downloadable-files a {
  text-decoration: none;
  color: black;
  opacity: 0.7;
}

.downloadable-files p {
  font-size: 20px;
}

.video-section {
  background-color: var(--background-purple);
}

@media only screen and (max-width: 310px) {
  .navigation-bar #navigation-bar-items > div {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 500px) {
  .event-date {
    margin: auto;
  }

  .events-card > a > div,
  .events-card > div > div {
    margin: auto;
    text-align: center;
  }

  .events-card > a > div > i,
  .events-card > div > div > i {
    margin: auto;
    padding: 20px;
  }

  .events-listing > ol > li > a {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  #navigation-bar-links {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .events-listing {
    width: 100%;
  }

  #carouselExampleControls {
    width: 100vw;
  }

  .carousel-inner {
    border-radius: 0px;
  }

  .out-now {
    padding-top: 0px !important;
    font-size: 36px !important;
  }

  #social-banner {
    height: 10vh !important;
  }

  #album-art {
    height: 350px !important;
    width: 100% !important;
  }

  .form input[type="submit"] {
    margin-top: 10px;
    justify-content: center;
  }

  .form input[type="text"] {
    min-width: 80vw !important;
  }

  #header-left {
    padding-left: 15vw !important;
  }

  #header-center {
    padding-left: 27vw !important;
  }

  #banner-video {
    height: 75vh !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  #album-art {
    height: 300px !important;
  }

  .form input[type="submit"] {
    margin-top: 10px;
    justify-content: center;
  }

  .form input[type="text"] {
    min-width: 80vw !important;
  }

  #header-left {
    padding-left: 27vw !important;
  }

  #header-center {
    padding-left: 32vw !important;
  }
}
